<template>
<article class="section">
<div class="container">
    <!-- I'm not too fond of this header, but it works for now... -->
    <nav class="block">
        <b-button tag="router-link" :to="{name: 'invoice-list'}" icon-left="arrow-left">
            Terug
        </b-button>
    </nav>
    <header class="block">
        <div class="level">
            <div class="level-left">
                <h1 class="title">Grootboekrekeningen</h1>
            </div>
        </div>
    </header>
    <section class="block">
        <form @submit.prevent="loadData">
            <!-- TODO: spacing is wrong -->
            <div class="columns">
                <div class="column">
                    <b-field label="Begindatum" horizontal>
                        <b-datepicker
                            v-model="startDate"
                            icon="calendar-today"
                            locale="nl-NL"
                            editable
                            position="is-bottom-right"
                            ref="startDatePicker"
                            :append-to-body="true"
                        >
                            <div class="buttons is-right">
                                <b-button type="is-primary" @click="$refs.startDatePicker.toggle()">
                                    OK
                                </b-button>
                            </div>
                        </b-datepicker>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Einddatum" horizontal>
                        <b-datepicker
                            v-model="endDate"
                            icon="calendar-today"
                            locale="nl-NL"
                            editable
                            position="is-bottom-right"
                            ref="endDatePicker"
                            :append-to-body="true"
                        >
                            <div class="buttons is-right">
                                <b-button type="is-primary" @click="$refs.endDatePicker.toggle()">
                                    OK
                                </b-button>
                            </div>
                        </b-datepicker>
                    </b-field>
                </div>
                <div class="column is-narrow buttons">
                    <b-button
                        :loading="isLoading"
                        :disabled="isLoading"
                        native-type="submit"
                        type="is-primary"
                    >
                        Bereken totalen
                    </b-button>
                    <b-button
                        :loading="isLoadingCsv"
                        :disabled="isLoadingCsv"
                        native-type="button"
                        icon-left="software-download"
                        @click="downloadCsv"
                    >
                        CSV
                    </b-button>
                </div>
            </div>
        </form>
    </section>
    <section class="block">
        <div class="table-container">
            <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th>Grootboekrekening</th>
                        <th class="has-text-right">Debet</th>
                        <th class="has-text-right">Credit</th>
                        <th class="has-text-right">Resultaat</th>
                    </tr>
                </thead>
                <tbody v-for="item, name in accounts" :key="name">
                    <template v-for="account in getAccountRows(name, item)">
                        <tr :key="name + account.name">
                            <td>
                                {{ '&nbsp;'.repeat(2 * Math.max(0, account.depth)) }}
                                {{ account.name }}
                            </td>
                            <td class="has-text-right has-text-weight-bold">
                                {{ account.totals.debet|formatMoney }}
                            </td>
                            <td class="has-text-right has-text-weight-bold">
                                {{ (account.totals.credit ? account.totals.credit * -1 : 0)|formatMoney }}
                            </td>
                            <td class="has-text-right has-text-weight-bold">
                                {{ account.totals.debet + account.totals.credit|formatMoney }}
                            </td>
                        </tr>
                        <template v-if="account.bins">
                            <tr v-for="totals, bin in account.bins" :key="`${account.name}${bin}`">
                                <td>
                                    {{ '&nbsp;'.repeat(2 * Math.max(0, account.depth+1)) }}
                                    {{ bin/100|formatNumber({style: 'percent'}) }}
                                </td>
                                <td class="has-text-right">
                                    {{ totals.debet|formatMoney }}
                                </td>
                                <td class="has-text-right">
                                    {{ (totals.credit ? totals.credit * -1 : 0)|formatMoney }}
                                </td>
                                <td class="has-text-right">
                                    {{ totals.debet + totals.credit|formatMoney }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
                <tfoot>
                    <v-with
                        v-slot="{totals}"
                        :totals="getTotals(accounts)"
                    >
                        <tr>
                            <th>Totaal</th>
                            <th class="has-text-right has-text-weight-bold">
                                {{ totals.debet|formatMoney }}
                            </th>
                            <th class="has-text-right has-text-weight-bold">
                                {{ (totals.credit ? totals.credit * -1 : 0)|formatMoney }}
                            </th>
                            <th class="has-text-right has-text-weight-bold">
                                {{ totals.debet + totals.credit|formatMoney }}
                            </th>
                        </tr>
                    </v-with>
                </tfoot>
            </table>
        </div>
    </section>
</div>
</article>
</template>

<script>
import { api } from '@/utils';
import { download } from '@/utils/functions';
export default {
    data() {
        return {
            isLoading: false,
            isLoadingCsv: false,
            accounts: {},
            startDate: null,
            endDate: null,
        };
    },
    methods: {
        *getAccountRows(name, accounts, depth=0) {
            // Converts treee into renderable rows
            if (accounts.bins) {
                yield {
                    name: name,
                    depth,
                    bins: accounts.bins,
                    totals: this.getTotals(accounts),
                };
            } else {
                yield {
                    name: name,
                    depth,
                    totals: this.getTotals(accounts),
                };
                for (const name in accounts)
                    yield* this.getAccountRows(name, accounts[name],depth + 1);
            }
        },
        getTotals(obj) {
            const reduce = (acc, x) => {
                acc.credit += x.credit;
                acc.debet += x.debet;
                return acc;
            };

            if (obj.bins) {
                return Object.values(obj.bins)
                             .reduce(reduce, {credit: 0, debet: 0});
            }

            return Object.values(obj)
                         .map(x => this.getTotals(x))
                         .reduce(reduce, {credit: 0, debet: 0});
        },  
        async loadData() {
            this.isLoading = true;
            const result = await api.getAccountTotals({
                start: this.startDate.toISOString().substring(0, 10),
                end: this.endDate.toISOString().substring(0, 10),
            });
            this.accounts = result.data;
            this.isLoading = false;
        },
        async downloadCsv() {
            this.isLoadingCsv = true;
            const result = await api.getAccountTotalsCsv({
                start: this.startDate.toISOString().substring(0, 10),
                end: this.endDate.toISOString().substring(0, 10),
            });
            download('grootboekrekeningen.csv', result.file, 'text/csv');
            this.isLoadingCsv = false;
        },
    },
};
</script>
