<template>
<article class="section">
<div class="container">
    <header class="block">
        <div class="level">
            <div class="level-left">
                <h1 class="title">Facturen</h1>
            </div>
            <div class="level-right">
                <b-button tag="router-link" :to="{name: 'account-totals'}" class="level-item">
                    Grootboekrekeningen
                </b-button>
                <b-button tag="router-link" :to="{name: 'invoice-totals'}" class="level-item">
                    Totalen
                </b-button>
                <b-button icon-left="math-plus" tag="router-link" :to="{ name: 'invoice-create-list'}" class="level-item">
                    Nieuwe facturen
                </b-button>
            </div>
        </div>
    </header>
    <section class="block">
        <div class="tabs is-boxed">
            <ul>
                <li :class="{'is-active': filter.type === 'other'}">
                    <a @click="filter.type = 'other'">Handmatig</a>
                </li>
                <li :class="{'is-active': filter.type === 'plan'}">
                    <a @click="filter.type = 'plan'">Abonnementen</a>
                </li>
                <li :class="{'is-active': filter.type === 'credit'}">
                    <a @click="filter.type = 'credit'">Creditfacturen</a>
                </li>
            </ul>
        </div>
        <div class="level">
            <div class="level-left">
                <b-button
                    :disabled="selectedItems.length == 0 || isSubmitting"
                    :loading="isLoadingPdf"
                    @click="downloadPdf"
                    icon-left="software-download"
                    class="level-item"
                >
                    PDF
                </b-button>
                <b-dropdown :disabled="selectedItems.length == 0 || isSubmitting" aria-role="list" class="level-item">
                    <!-- TODO: aria-role=listbox + aria-role=option would be more fitting, but not supported by buefy -->
                    <template #trigger>
                        <b-button label="Update status"></b-button>
                    </template>

                    <b-dropdown-item aria-role="listitem" @click="updateStatus('ready')">Klaar voor verwerken</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="updateStatus('review')">Review</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="updateStatus('correction')">Corrigeren</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="updateStatus('manual')">Handmatig</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="updateStatus('paid')">Betaald</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="updateStatus('ignored')">Niet meer incasseren</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="updateStatus('payment_agreement')">Betalingsregeling getroffen</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="level-item has-text-grey" v-if="selectedItems.length > 0">
                Selectie: {{ selectedItems.length }}
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-dropdown v-model="filter.status" aria-role="list">
                        <template v-if="filter.status" #trigger>
                            <b-button icon-right="chevron-down">
                                Status: {{ filter.status|formatStatus }}
                            </b-button>
                        </template>
                        <template v-else #trigger>
                            <b-button icon-right="chevron-down">
                                Status
                            </b-button>
                        </template>
                        <b-dropdown-item :value="null" aria-role="listitem">Alle</b-dropdown-item>
                        <b-dropdown-item value="ready" aria-role="listitem">Klaar voor verwerken</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_open" aria-role="listitem">Incasso aangemeld</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_canceled" aria-role="listitem">Incasso geannuleerd door klant</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_pending" aria-role="listitem">Incasso in afwachting</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_authorized" aria-role="listitem">Incasso geautoriseerd</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_expired" aria-role="listitem">Incasso verlopen</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_failed" aria-role="listitem">Incasso mislukt</b-dropdown-item>
                        <b-dropdown-item value="direct_debit_chargeback" aria-role="listitem">Incasso chargeback</b-dropdown-item>
                        <b-dropdown-item value="email_sent" aria-role="listitem">Factuur gemaild</b-dropdown-item>
                        <b-dropdown-item value="reminder_sent" aria-role="listitem">Herinnering gemaild</b-dropdown-item>
                        <b-dropdown-item value="paid" aria-role="listitem">Betaald</b-dropdown-item>
                        <b-dropdown-item value="review" aria-role="listitem">Review</b-dropdown-item>
                        <b-dropdown-item value="correction" aria-role="listitem">Corrigeren</b-dropdown-item>
                        <b-dropdown-item value="manual" aria-role="listitem">Handmatig</b-dropdown-item>
                        <b-dropdown-item value="ignored" aria-role="listitem">Niet meer incasseren</b-dropdown-item>
                        <b-dropdown-item value="payment_agreement" aria-role="listitem">Betalingsregeling getroffen</b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="field has-addons level-item">
                    <div class="control">
                        <label for="search-field" class="is-sr-only">
                            Zoek factuur
                        </label>
                        <input
                            id="search-field"
                            class="input"
                            type="search"
                            placeholder="Zoek factuur"
                            @input="handleFilter($event.target.value)"
                        />
                    </div>
                    <div class="control">
                        <button class="button">
                            <span class="icon">
                                <span class="is-sr-only">Zoeken</span>
                                <i class="gg-search" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-table
            :data="data"
            :striped="true"
            :hoverable="true"
            :loading="view.isLoading || isSubmitting"
            :mobile-cards="false"
            class="has-sticky-footer"

            checkable
            :checked-rows.sync="selectedItems"

            paginated
            backend-pagination
            :total="meta.total"
            :per-page="meta.page_size"
            @page-change="handlePageChange"
            :current-page="meta.page"
            backend-sorting
            @sort="handleSort"
        >
            <b-table-column field="invoice_number" label="Factuurnummer" cell-class="is-vcentered" v-slot="props" sortable>
                {{ props.row.invoice_number }}
            </b-table-column>
            <b-table-column field="invoice_date" label="Factuurdatum" cell-class="is-vcentered" v-slot="props" sortable>
                {{ props.row.invoice_date|formatTimestamp('YYYY-MM-DD') }}
            </b-table-column>
            <b-table-column field="due_date" label="Verloopdatum" cell-class="is-vcentered" v-slot="props" sortable>
                <v-with :expired="props.row.due_date && props.row.status !== 'paid' && new Date(props.row.due_date) < new Date()" v-slot="{expired}">
                    <span :class="{'has-text-danger': expired}">
                        {{ props.row.due_date|formatTimestamp('YYYY-MM-DD') }}
                        <b-tooltip v-show="expired" label="Betaaltermijn verlopen" type="is-dark" position="is-right">
                            <span class="icon has-text-danger">
                                <i class="gg-danger" aria-hidden="true"></i>
                                <span class="is-sr-only">betaaltermijn verlopen</span>
                            </span>
                        </b-tooltip>
                    </span>
                </v-with>
            </b-table-column>
            <b-table-column field="organisation_id" label="Organisatie" cell-class="is-vcentered" v-slot="props" sortable>
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation">
                            {{ organisation.name }}
                            <b-tooltip
                                v-if="organisation.payment && organisation.payment.status === 'ready'"
                                label="Organisatie heeft een actief incassocontract"
                                type="is-dark"
                                position="is-right"
                            >
                                <b-icon icon="check-o" type="is-success"></b-icon>
                            </b-tooltip>
                            <b-tooltip
                                v-if="organisation.payment && organisation.payment.status !== 'ready'"
                                label="Fout in incasso-contract"
                                type="is-dark"
                                position="is-right"
                            >
                                <b-icon icon="danger" type="is-danger"></b-icon>
                            </b-tooltip>
                        </template>
                        <b-skeleton :animated="true" :active="props.row.organisation_id && !organisation"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="totals.amount" label="Bedrag (excl. BTW)" cell-class="is-vcentered" numeric v-slot="props">
                {{ props.row.totals.amount|formatMoney }}
            </b-table-column>
            <b-table-column field="totals.amount" label="Bedrag (incl. BTW)" cell-class="is-vcentered" numeric v-slot="props">
                {{ props.row.totals.amount + props.row.totals.vat|formatMoney }}
            </b-table-column>
            <b-table-column field="status" label="Status" cell-class="is-vcentered" v-slot="props" sortable>
                {{ props.row.status|formatStatus }}
            </b-table-column>
            <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
                <b-button
                    tag="router-link"
                    :to="{ name: 'invoice-single', params: {invoiceId: props.row.id} }"
                    size="is-small"
                >
                    <span class="icon is-small">
                        <i class="gg-eye" aria-hidden="true"></i>
                        <span class="is-sr-only">Details</span>
                    </span>
                </b-button>
            </b-table-column>

            <template #footer v-if="selectedItems.length != 0">
                <th colspan="4" class="has-text-right">
                    Selectie totaal:
                </th>
                <th>
                    {{ selectedItems.length }} facturen
                </th>
                <th class="has-text-right">
                    {{ totals.amount|formatMoney }}
                </th>
                <th class="has-text-right">
                    {{ (totals.amount + totals.vat)|formatMoney }}
                </th>
                <th colspan="2"></th>
            </template>
            <template #footer v-else></template>

            <template #empty>
                <div class="has-text-centered">Geen facturen gevonden</div>
            </template>
        </b-table>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';
import pagination from '@/mixins/pagination';
import { download } from '@/utils/functions';

export default {
    mixins: [pagination],
    data() {
        return {
            viewName: 'invoiceList',
            loadViewMethod: 'invoice/loadView',
            selectedItems: [],
            isLoadingPdf: false,
            isSubmitting: false,
            filter: {
                type: 'other',
                status: null,
            },
        };
    },
    computed: {
        ...mapState({
            organisations: state => state.organisation.all,
            view(state) { return state.invoice.views[this.viewName]; },
        }),
        totals() {
            return this.selectedItems.reduce((acc, invoice) => {
                acc.amount += invoice.totals?.amount || 0;
                acc.vat += invoice.totals?.vat || 0;
                return acc;
            }, {amount: 0, vat:0});
        },
    },
    methods: {
        getOrganisation(organisationId) {
            return this.organisations.find(o => o.id == organisationId);
        },
        afterUpdate() {
            this.loadOrganisations();
        },
        async loadOrganisations() {
            // async so this call can run in the background
            const ids = [...new Set(this.data
                .filter(i => i.organisation_id && !this.getOrganisation(i.organisation_id))
                .map(i => i.organisation_id)
            )];
            if (ids.length > 0)
                await this.$store.dispatch('organisation/loadView', {filter: {ids}});
        },
        async updateStatus(status) {
            this.isSubmitting = true;
            try {
                await this.$store.dispatch('invoice/updateInvoices', {
                    ids: this.selectedItems.map(i => i.id),
                    updates: { status },
                });
            } catch (e) {
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: `Er is een fout opgetreden tijdens het updaten van de status. Technische informatie: ${e}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
            } finally {
                this.isSubmitting = false;
            }
        },
        async downloadPdf() {
            this.isLoadingPdf = true;
            try {
                const file = await this.$store.dispatch('invoice/getPdfs', this.selectedItems.map(i => i.id));
                download('invoices.zip', file, 'application/zip', 'base64');
            } catch (e) {
                if (e.response?.type === 'missing_invoice_number')
                    this.$buefy.dialog.alert({
                        title: 'Factuurnummer is verplicht!',
                        message: 'Je kunt alleen een PDF maken van facturen met een factuurnummer. Controleer of alle geselecteerde facturen een factuurnummer hebben.',
                        type: 'is-danger',
                        hasIcon: true,
                    });
                else
                    this.$buefy.dialog.alert({
                        title: 'Er ging iets mis!',
                        message: `Er is een fout opgetreden tijdens het updaten van de status. Technische informatie: ${e}`,
                        type: 'is-danger',
                        hasIcon: true,
                    });
            } finally {
                this.isLoadingPdf = false;
            }
        },
    },
    filters: {
        formatStatus(status) {
            const mapping = {
                'ready': 'Klaar voor verwerken',
                'direct_debit_open': 'Incasso aangemeld',
                'direct_debit_canceled': 'Incasso geannuleerd door klant',
                'direct_debit_pending': 'Incasso in afwachting',
                'direct_debit_authorized': 'Incasso geautoriseerd',
                'direct_debit_expired': 'Incasso verlopen',
                'direct_debit_failed': 'Incasso mislukt',
                'direct_debit_chargeback': 'Incasso chargeback',
                'email_sent': 'Factuur gemaild',
                'reminder_sent': 'Herinnering gemaild',
                'paid': 'Betaald',
                'review': 'Review',
                'correction': 'Corrigeren',
                'manual': 'Handmatig',
                'ignored': 'Niet meer incasseren',
                'payment_agreement': 'Betalingsregeling getroffen',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
    watch: {
        data(newVal) {
            this.selectedItems = this.selectedItems.filter(id => newVal.find(item => item.id === id));
        },
    }
};
</script>
