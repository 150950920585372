<template>
    <b-tooltip 
        v-if="errors"
        type="is-dark"
        multilined
        position="is-right"
    >
        <span class="icon has-text-danger">
            <i class="gg-danger" aria-hidden="true"></i>
        </span>
        <template v-slot:content>
            <ul>
                <li v-for="error in errors" :key="error">
                    {{ error }}
                </li>
            </ul>
        </template>
    </b-tooltip>
</template>

<script>
export default {
    props: {
        errors: Array,
    },
};
</script>
