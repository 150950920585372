<template>
<article class="section">
<div class="container">
    <header class="block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
            <b-button tag="router-link" :to="{name: 'invoice-list'}" icon-left="arrow-left">
                Terug
            </b-button>
        </nav>
        <div class="level">
            <div class="level-left">
                <h1 class="title">Nieuwe facturen</h1>
            </div>
            <div class="level-right">
                <div class="field has-addons level-item">
                    <div class="control">
                        <label for="search-field" class="is-sr-only">
                            Zoek organisatie
                        </label>
                        <input
                            id="search-field"
                            class="input"
                            type="search"
                            placeholder="Zoek organisatie"
                            @input="handleFilter($event.target.value)"
                        />
                    </div>
                    <div class="control">
                        <button class="button">
                            <span class="icon">
                                <span class="is-sr-only">Zoeken</span>
                                <i class="gg-search" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <section class="block">
        <b-table
            :data="data"
            :striped="true"
            :hoverable="true"
            :loading="view.isLoading"
            :mobile-cards="false"

            paginated
            backend-pagination
            :total="meta.total"
            :per-page="meta.page_size"
            @page-change="handlePageChange"
            :current-page="meta.page"
            backend-sorting
            @sort="handleSort"
        >
            <b-table-column field="id" :visible="false" sortable/><!-- Hack for default sort -->
            <b-table-column field="name" label="Naam" cell-class="is-vcentered" v-slot="props">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="contact.email" label="E-mail" cell-class="is-vcentered" v-slot="props">
                <template v-if="props.row.contact">
                    {{ props.row.contact.email }}
                </template>
            </b-table-column>
            <b-table-column label="Laatste factuur" cell-class="is-vcentered" v-slot="props">
                <v-with :invoice="getLatestInvoice(props.row)" v-slot="{invoice}">
                    <div>
                        <template v-if="!isLoadingLatestInvoices">
                            <template v-if="invoice">
                                <template v-if="invoice.invoice_number">
                                    {{ invoice.invoice_number }}
                                </template>
                                <em v-else>
                                    Ongenummerde factuur
                                </em>
                                ({{ invoice.invoice_date|formatTimestamp('YYYY-MM-DD') }})
                            </template>
                            <template v-else>
                                –
                            </template>
                        </template>
                        <b-skeleton :animated="true" :active="isLoadingLatestInvoices"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column label="Ongefactureerde labels" cell-class="is-vcentered" v-slot="props">
                <v-with :labelCount="labelCounts.find(i => i.organisation_id == props.row.id)" v-slot="{labelCount}">
                    <div>
                        <template v-if="labelCount">
                            {{ labelCount.label_count }}
                        </template>
                        <template v-else-if="!isLoadingLabelCounts">
                            0
                        </template>
                        <b-skeleton :animated="true" :active="isLoadingLabelCounts" width="5ex"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
                <b-button
                    tag="router-link"
                    :to="{ name: 'invoice-form', params: {organisationId: props.row.id} }"
                    size="is-small"
                >
                    <span class="icon is-small">
                        <i class="gg-math-plus" aria-hidden="true"></i>
                        <span class="is-sr-only">Factuur maken</span>
                    </span>
                </b-button>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Geen organisaties met ongefactureerde labels gevonden</div>
            </template>
        </b-table>
    </section>
</div>
</article>
</template>

<script>
import { mapState } from 'vuex';
import pagination from '@/mixins/pagination';
import { api } from '@/utils';

export default {
    mixins: [pagination],
    data() {
        return {
            labelCounts: [],
            viewName: 'invoiceCreateList',
            loadViewMethod: 'organisation/loadView',
            isLoadingLatestInvoices: false,
            isLoadingLabelCounts: false,
        };
    },
    computed: {
        ...mapState({
            latestInvoices: state => state.invoice.views?.organisationLatest?.data?.data,
            view(state) { return state.organisation.views[this.viewName]; },
        }),
    },
    methods: {
        getLatestInvoice(organisation) {
            return this.latestInvoices?.find(o => o.organisation_id === organisation.id);
        },
        async loadLatestInvoices() {
            // async so this call can run in the background
            this.isLoadingLatestInvoices = true;
            await this.$store.dispatch('invoice/loadView', {
                view: 'organisationLatest',
                filter: {
                    organisation_id: [...this.data.map(i => i.id)],
                    organisation_latest: true,
                },
            });
            this.isLoadingLatestInvoices = false;
        },
        async loadLabelCounts() {
            // async so this call can run in the background
            this.isLoadingLabelCounts = true;
            const ids = [...this.data.map(i => i.id)];
            const result = await api.getInvoiceLabelCounts({organisation_id: ids});
            this.labelCounts = result.data;
            this.isLoadingLabelCounts = false;
        },
        afterUpdate() {
            this.loadLatestInvoices();
            this.loadLabelCounts();
        },
    },
};
</script>
