<template>
<article class="section">
<div class="container">
    <!-- I'm not too fond of this header, but it works for now... -->
    <nav class="block">
        <b-button tag="router-link" :to="{name: 'invoice-list'}" icon-left="arrow-left">
            Terug
        </b-button>
    </nav>
    <header class="block">
        <div class="level">
            <div class="level-left">
                <h1 class="title">Factuur totalen</h1>
            </div>
        </div>
    </header>
    <section class="block">
        <h2 class="title">Per status</h2>
        <b-table
            :data="totals.status"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"
        >
            <b-table-column field="status" label="Status" v-slot="props">
                {{ props.row.status|formatStatus }}
            </b-table-column>
            <b-table-column field="amount" label="Bedrag (excl. BTW)" numeric v-slot="props">
                {{ props.row.amount|formatMoney }}
            </b-table-column>
            <b-table-column field="vat" label="BTW bedrag" numeric v-slot="props">
                {{ props.row.vat|formatMoney }}
            </b-table-column>
            <b-table-column field="amount" label="Bedrag (incl. BTW)" numeric v-slot="props">
                {{ props.row.amount + props.row.vat|formatMoney }}
            </b-table-column>
        </b-table>
    </section>
    <section class="block">
        <h2 class="title">Per factuurperiode</h2>
        <b-table
            :data="totals.invoicePeriod"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"
        >
            <b-table-column field="invoice_period_start" label="Periode" v-slot="props">
                <template v-if="props.row.invoice_period_start">
                    {{ props.row.invoice_period_start|formatTimestamp('YYYY-MM-DD') }}
                    <template v-if="props.row.invoice_period_end">
                        –
                        {{ props.row.invoice_period_end|formatTimestamp('YYYY-MM-DD') }}
                    </template>
                </template>
                <template v-else>
                    Geen periode
                </template>
            </b-table-column>
            <b-table-column field="amount" label="Bedrag (excl. BTW)" numeric v-slot="props">
                {{ props.row.amount|formatMoney }}
            </b-table-column>
            <b-table-column field="vat" label="BTW bedrag" numeric v-slot="props">
                {{ props.row.vat|formatMoney }}
            </b-table-column>
            <b-table-column field="amount" label="Bedrag (incl. BTW)" numeric v-slot="props">
                {{ props.row.amount + props.row.vat|formatMoney }}
            </b-table-column>
        </b-table>
    </section>
</div>
</article>
</template>

<script>
import { api } from '@/utils';
export default {
    data() {
        return {
            isLoading: false,
            totals: {
                invoicePeriod: [],
                status: [],
            },
        };
    },
    methods: {
        async loadTotals() {
            this.isLoading = true;
            const result = await api.getInvoiceTotals();
            this.totals.invoicePeriod = result.data.invoice_period;
            this.totals.status = result.data.status;
            this.isLoading = false;
        },
    },
    filters: {
        formatStatus(status) {
            const mapping = {
                'ready': 'Klaar voor verwerken',
                'direct_debit_open': 'Incasso aangemeld',
                'direct_debit_canceled': 'Incasso geannuleerd door klant',
                'direct_debit_pending': 'Incasso in afwachting',
                'direct_debit_authorized': 'Incass geautoriseerd',
                'direct_debit_expired': 'Incasso verlopen',
                'direct_debit_failed': 'Incasso mislukt',
                'email_sent': 'Factuur gemaild',
                'reminder_sent': 'Herinnering gemaild',
                'paid': 'Betaald',
                'review': 'Review',
                'correction': 'Corrigeren',
                'manual': 'Handmatig',
                'ignored': 'Niet meer incasseren',
                'payment_agreement': 'Betalingsregeling getroffen',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
    created() {
        this.loadTotals();
    },
};
</script>
