<template>
<article class="section">
<div class="container">
    <header class=" block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <div class="level">
            <nav class="level-left">
                <b-button v-if="returnRoute" tag="router-link" :to="returnRoute" icon-left="arrow-left" class="level-item">
                    Volgende factuur
                </b-button>
                <b-button v-else @click="$router.backOrDefault(defaultReturnRoute)" icon-left="arrow-left" class="level-item">
                    Terug
                </b-button>
            </nav>
            <div class="level-right" v-if="invoice">
                <b-button
                    :disabled="!invoice.invoice_number"
                    :loading="isLoadingPdf"
                    @click="downloadPdf"
                    icon-left="software-download"
                    class="level-item"
                >
                    PDF
                </b-button>
                <b-field class="level-item">
                    <p class="control">
                        <b-button 
                            :disabled="!invoice.invoice_number || invoice.status !== 'ready'"
                            :loading="isSubmittingPayment || isSubmittingMail" 
                            @click="submitStartPaymentProcess"
                        >
                            Betaalproces starten
                        </b-button>
                    </p>
                    <p class="control">
                        <b-dropdown position="is-bottom-left">
                            <button class="button" slot="trigger">
                                <span class="icon">
                                    <i class="gg-chevron-down" aria-hidden="true"></i>
                                    <span class="is-sr-only">Meer opties…</span>
                                </span>
                            </button>

                            <b-dropdown-item
                                aria-role="listitem"
                                :disabled="!invoice.invoice_number || invoice.status === 'paid'"
                                :loading="isSubmittingMail" 
                                @click="submitMail('mail')"
                            >
                                <b-icon icon="mail"></b-icon>
                                Factuur mailen
                            </b-dropdown-item>
                            <b-dropdown-item
                                aria-role="listitem"
                                :disabled="!invoice.invoice_number || invoice.status === 'paid'"
                                :loading="isSubmittingMail" 
                                @click="submitMail('reminder')"
                            >
                                <b-icon icon="mail"></b-icon>
                                Herinnering mailen
                            </b-dropdown-item>
                            <b-dropdown-item
                                aria-role="listitem"
                                :disabled="!canCreatePayment || !invoice.invoice_number || invoice.status === 'paid'"
                                :loading="isSubmittingPayment" 
                                @click="submitPayment"
                            >
                                <b-icon icon="euro"></b-icon>
                                Incasso aanmaken
                            </b-dropdown-item>
                        </b-dropdown>
                    </p>
                </b-field>
            </div>
        </div>
        <h1 class="title">
            <template v-if="invoice && invoice.totals.amount < 0">
                Creditfactuur
            </template>
            <template v-else>
                Factuur
            </template>
            <template v-if="invoice">{{ invoice.invoice_number }}</template>
        </h1>
    </header>
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
    <section class="block" v-if="invoice">
        <table class="table is-narrow">
            <tbody>
                <tr v-if="invoice.organisation_id">
                    <th>Organisatie</th>
                    <td v-if="organisation">
                        {{ organisation.name }}
                        <b-tooltip
                            v-if="organisation.payment"
                            label="Organisatie heeft een actief incassocontract"
                            type="is-dark"
                            position="is-right"
                        >
                            <b-icon icon="check-o" type="is-success"></b-icon>
                        </b-tooltip>
                    </td>
                    <td v-else><b-skeleton :animated="true"></b-skeleton></td>
                    <td></td>
                </tr>
                <tr>
                    <th>Factuurnummer</th>
                    <td>
                        <template v-if="invoice.invoice_number">
                            {{ invoice.invoice_number }}
                        </template>
                        <form v-else id="invoice-number-form" @submit.prevent="submitInvoiceNumber">
                            <b-field grouped>
                                <b-field>
                                    <!-- Note: selected state is muted on purpose. This is bad design, but I don't want this inline form to jump off the page too much. -->
                                    <b-radio-button
                                        v-model="newInvoiceNumber"
                                        native-value="AUTO_INCREMENT"
                                        size="is-small"
                                        type="is-black is-outlined"
                                    >
                                        Automatisch
                                    </b-radio-button>
                                    <!-- :value + @input hack to not un-highlight manual input when value is entered -->
                                    <b-radio-button
                                        :value="newInvoiceNumber === 'AUTO_INCREMENT' ? 'AUTO_INCREMENT' : ''"
                                        @input="newInvoiceNumber = ''"
                                        native-value=""
                                        size="is-small"
                                        type="is-black is-outlined"
                                    >
                                        Handmatig
                                    </b-radio-button>
                                </b-field>
                                <b-input v-show="newInvoiceNumber !== 'AUTO_INCREMENT'" v-model="newInvoiceNumber" aria-label="Factuurnummer" placeholder="Factuurnummer" size="is-small" autofocus></b-input>
                                <p class="control">
                                    <b-button size="is-small" native-type="submit" :loading="isSubmitting">Update!</b-button>
                                </p>
                            </b-field>
                        </form>
                    </td>
                    <td></td>
                </tr>
                <tr><th>Factuurdatum</th><td>{{ invoice.invoice_date|formatTimestamp('YYYY-MM-DD') }}</td><td></td></tr>
                <tr>
                    <th>Verloopdatum</th>
                    <v-with :expired="invoice.due_date && invoice.status !== 'paid' && new Date(invoice.due_date) < new Date()" v-slot="{expired}">
                        <td :class="{'has-text-danger': expired}">
                            {{ invoice.due_date|formatTimestamp('YYYY-MM-DD') }}
                            <b-tooltip v-show="expired" label="Betaaltermijn verlopen" type="is-dark" position="is-right">
                                <span class="icon has-text-danger">
                                    <i class="gg-danger" aria-hidden="true"></i>
                                    <span class="is-sr-only">betaaltermijn verlopen</span>
                                </span>
                            </b-tooltip>
                        </td>
                    </v-with>
                    <td></td>
                </tr>
                <tr v-if="invoice.invoice_period_start">
                    <th>Factuurperiode</th>
                    <td>
                        {{ invoice.invoice_period_start|formatTimestamp('YYYY-MM-DD') }}
                        <template v-if="invoice.invoice_period_end">
                            –
                            {{ invoice.invoice_period_end|formatTimestamp('YYYY-MM-DD') }}
                        </template>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <th class="is-vcentered">Status</th>
                    <td class="is-vcentered">
                        {{ invoice.status|formatInvoiceStatus }}
                    </td>
                    <td>
                        <b-dropdown :disabled="isSubmitting" aria-role="list">
                            <!-- TODO: aria-role=listbox + aria-role=option would be more fitting, but not supported by buefy -->
                            <template #trigger>
                                <b-button :loading="isSubmitting" aria-label="Update status" size="is-small">
                                    <span class="icon is-small">
                                        <i class="gg-pen" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                            </template>

                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'ready'})">Klaar voor verwerken</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'review'})">Review</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'correction'})">Corrigeren</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'manual'})">Handmatig</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'paid'})">Betaald</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'ignored'})">Niet meer incasseren</b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="submitUpdate({status: 'payment_agreement'})">Betalingsregeling getroffen</b-dropdown-item>
                        </b-dropdown>
                    </td>
                </tr>
                <tr>
                    <th>Incasso gemaakt</th>
                    <td v-if="!canCreatePayment">N.V.T.</td>
                    <td v-else-if="directDebits.length == 0">–</td>
                    <td v-else>
                        {{ directDebits[directDebits.length-1].created_at|formatTimestamp }}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <th>E-mail verstuurd</th>
                    <td v-if="sentEmails.length == 0">–</td>
                    <td v-else>
                        <b-tooltip type="is-dark" position="is-right" multilined dashed>
                            {{ sentEmails[sentEmails.length-1].timestamp|formatTimestamp }}
                            <template v-if="sentEmails.length > 1">({{sentEmails.length}})</template>
                            <template v-slot:content>
                                <p class="has-text-left has-text-weight-bold">
                                    {{ sentEmails.length|pluralize('E-mail', 'E-mails') }} verstuurd op
                                </p>
                                <ul class="has-text-left">
                                    <li v-for="mail in sentEmails" :key="mail.timestamp">
                                        {{ mail.timestamp|formatTimestamp }}
                                    </li>
                                </ul>
                            </template>
                        </b-tooltip>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <th>Herinnering verstuurd</th>
                    <td v-if="sentReminders.length == 0">–</td>
                    <td v-else>
                        <b-tooltip type="is-dark" position="is-right" multilined dashed>
                            {{ sentReminders[sentReminders.length-1].timestamp|formatTimestamp }}
                            <template v-if="sentReminders.length > 1">({{sentReminders.length}})</template>
                            <template v-slot:content>
                                <p class="has-text-left has-text-weight-bold">
                                    {{ sentReminders.length|pluralize('Herinnering', 'Herinneringen') }} verstuurd op
                                </p>
                                <ul class="has-text-left">
                                    <li v-for="mail in sentReminders" :key="mail.timestamp">
                                        {{ mail.timestamp|formatTimestamp }}
                                    </li>
                                </ul>
                            </template>
                        </b-tooltip>
                    </td>
                    <td></td>
                </tr>
                <tr v-if="invoice.chargebacks.length > 0">
                    <th>Chargebacks</th>
                    <td>
                        <p v-for="chargeback in invoice.chargebacks" :key="chargeback.chargeback_id">
                            <template v-if="chargeback.reason">
                                {{ chargeback.reason.description || 'Onbekende reden' }}
                                ({{ chargeback.reason.code || 'geen code' }})
                            </template>
                            <template v-else>
                                Onbekende reden
                            </template>
                            {{ chargeback.created_at|formatTimestamp }}
                        </p>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </section>
    <section class="table-container block" v-if="invoice">
        <table class="table is-fullwidth is-hoverable is-no-wrap">
            <thead>
                <tr>
                    <th>Omschrijving</th>
                    <th class="has-text-right">Aantal</th>
                    <th class="has-text-right">Prijs</th>
                    <th class="has-text-right">BTW %</th>
                    <th class="has-text-right">Bedrag (excl. BTW)</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in getInvoiceItems(invoice)" :key="idx">
                    <template v-if="item.quantity">
                        <td class="is-vcentered">
                            {{ '&nbsp;'.repeat(2 * Math.max(0, item.depth)) }}
                            {{ item.description }}
                        </td>
                        <td class="is-vcentered has-text-right">{{ item.quantity }}</td>
                        <td class="is-vcentered has-text-right">
                            <template v-if="item.unit_price !== null">
                                {{ item.unit_price|formatMoney }}
                            </template>
                        </td>
                        <td class="is-vcentered has-text-right">{{ item.vat }}%</td>
                        <td class="is-vcentered has-text-right">
                            <template v-if="item.unit_price !== null">
                                {{ item.quantity * item.unit_price|formatMoney }}
                            </template>
                        </td>
                        <td class="is-vcentered buttons is-right">
                            <b-button
                                v-if="item.fullId in labels"
                                size="is-small"
                                @click="currentItem = item"
                            >
                                <span class="icon is-small">
                                    <i class="gg-eye" aria-hidden="true"></i>
                                    <span class="is-sr-only">Details</span>
                                </span>
                            </b-button>
                        </td>
                    </template>
                    <template v-else>
                        <th colspan="6">
                            {{ '&nbsp;'.repeat(2 * Math.max(0, item.depth)) }}
                            {{ item.description || 'asdsafd' }}
                        </th>
                    </template>
                </tr>
            </tbody>
            <tbody v-if="invoice.totals && invoice.totals.amount !== null">
                <tr>
                    <th colspan="4">Subtotaal</th>
                    <th class="has-text-right">{{ invoice.totals.amount|formatMoney }}</th>
                    <th></th>
                </tr>
                <tr>
                    <td colspan="4">BTW</td>
                    <td class="has-text-right">{{ invoice.totals.vat|formatMoney }}</td>
                    <td></td>
                </tr>
            </tbody>
            <tfoot v-if="invoice.totals && invoice.totals.amount !== null">
                <tr>
                    <th colspan="4">Totaal</th>
                    <th class="has-text-right">{{ invoice.totals.amount + invoice.totals.vat|formatMoney }}</th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    </section>
</div>
<b-modal
    v-model="hasCurrentItem"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Labels"
    aria-modal
    @close="currentItem = null"
>
    <template #default="props">
        <div class="modal-card" v-if="currentItem">
            <header class="modal-card-head">
                <p class="modal-card-title">{{currentItem.fullDescription}}</p>
                <button type="button" class="delete" @click="props.close"></button>
            </header>
            <section class="modal-card-body">
                <b-table
                    :data="currentLabels"
                    :paginated="true"
                    :per-page="10"
                    :striped="true"
                    :hoverable="true"
                    :mobile-cards="false"
                    default-sort="id"
                >
                    <b-table-column field="id" :visible="false" sortable/><!-- Hack for default sort -->
                    <b-table-column field="created_at" label="Gemaakt" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.created_at|formatTimestamp }}
                    </b-table-column>
                    <b-table-column field="order_number" label="Ordernummer" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.order_number }}
                    </b-table-column>
                    <b-table-column field="tracking_number" label="Trackingnummer" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.tracking_number }}
                    </b-table-column>
                    <b-table-column field="status" label="Status" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.status|formatLabelStatus }}
                        <span v-if="!props.row.is_active" class="tag is-danger">
                            Verwijderd
                        </span>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">Geen labels gevonden</div>
                    </template>
                </b-table>
            </section>
            <footer class="modal-card-foot buttons is-right">
                <b-button type="is-primary" @click="props.close">OK</b-button>
            </footer>
        </div>
    </template>
</b-modal>
</article>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/utils';
import { ApiError } from '@/utils/api';
import { download } from '@/utils/functions';

export default {
    data() {
        return {
            invoiceId: this.$route.params.invoiceId,
            labels: {},
            organisation: null,
            currentItem: null,
            newInvoiceNumber: '',
            isSubmitting: false,
            isSubmittingPayment: false,
            isSubmittingMail: false,
            isLoadingPdf: false,
            isLoading: false,
            defaultReturnRoute: { name: 'invoice-list' },
            returnRoute: this.$route.params.returnRoute,
        }
    },
    computed: {
        ...mapState({
            invoice(state) {
                return state.invoice.all.find(o => o.id === this.$route.params.invoiceId);
            },
        }),
        currentLabels() {
            if (this.currentItem && this.currentItem.fullId)
                return this.labels[this.currentItem.fullId];
            return [];
        },
        canCreatePayment() {
            return this.invoice && this.organisation?.payment && this.invoice.totals.amount > 0;
        },
        sentEmails() {
            return this.invoice?.sent_emails
                ?.filter(i => i.type === 'mail')
                .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) || [];
        },
        sentReminders() {
            return this.invoice?.sent_emails
                ?.filter(i => i.type === 'reminder')
                .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) || [];
        },
        directDebits() {
            return this.invoice?.payments
                ?.filter(i => i.type === 'direct_debit')
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) || [];
        },
        hasCurrentItem: {
            get() {
                return Boolean(this.currentItem);
            },
            set(value) {
                if (!value)
                    this.currentItem = null;
            },
        },
    },
    methods: {
        *getInvoiceItems(invoice, depth=-1, fullDescription='', fullId='') {
            if (invoice.description)
                fullDescription = `${fullDescription} ${invoice.description}`;

            if (fullId !== '')
                fullId = `${fullId}|${invoice.id}`;
            else if (depth != -1)
                fullId = invoice.id;

            if (invoice.items !== undefined) {
                if (invoice.description)
                    yield {
                        description: invoice.description,
                        id: invoice.id,
                        depth,
                    };

                for (let item of invoice.items)
                    yield* this.getInvoiceItems(item, depth + 1, fullDescription, fullId);
            } else {
                yield {
                    ...invoice,
                    depth,
                    fullDescription,
                    fullId,
                };
            }
        },
        async getLabels() {
            this.labels = await api.getInvoiceLabels(this.$route.params.invoiceId);
        },
        async submitMail(message_type='mail') {
            this.isSubmittingMail = true;
            try {
                await this.$store.dispatch('invoice/sendMail', {invoice: this.invoice, message_type});
                this.$buefy.toast.open({
                    message: 'E-mail verzonden!',
                    type: 'is-success'
                });
            } catch (e) {
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: `Er is een fout opgetreden tijdens het versturen van de email. Technische informatie: ${e}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
            } finally {
                this.isSubmittingMail = false;
            }
        },
        async submitPayment() {
            this.isSubmittingPayment = true;
            try {
                await this.$store.dispatch('invoice/createPayment', this.invoice);
                this.$buefy.toast.open({
                    message: 'Incasso aangemaakt!',
                    type: 'is-success'
                });
            } catch (e) {
                let message = `
                    Er is een fout opgetreden tijdens het aanmaken van de betaling.<br><br>
                    <strong>Technische informatie:</strong><br>
                    ${e}
                `;
                if (e instanceof ApiError && e.response.type)
                    message += `<br><br><pre>${JSON.stringify(e.response, 0, 2)}</pre>`;
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: message,
                    type: 'is-danger',
                    hasIcon: true,
                });
            } finally {
                this.isSubmittingPayment = false;
            }
        },
        async submitStartPaymentProcess() {
            // TODO: Wouldn't it be better to leave this to the backend?
            await this.submitMail('mail');
            if (this.organisation?.payment && this.invoice.totals.amount > 0)
                await this.submitPayment();
        },
        submitInvoiceNumber() {
            this.submitUpdate({invoice_number: this.newInvoiceNumber});
        },
        async submitUpdate(updates) {
            this.isSubmitting = true;
            try {
                await this.$store.dispatch('invoice/updateInvoice', {id: this.invoice.id, updates});
                this.$buefy.toast.open({
                    message: 'Factuur geüpdatet!',
                    type: 'is-success'
                });
            } catch (e) {
                this.$buefy.dialog.alert({
                    title: 'Er ging iets mis!',
                    message: `Er is een fout opgetreden tijdens het updaten van de factuur. Technische informatie: ${e}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
            } finally {
                this.isSubmitting = false;
            }
        },
        async downloadPdf() {
            this.isLoadingPdf = true;
            const file = await this.$store.dispatch('invoice/getPdf', this.invoice);
            download(`invoice_${this.invoice.invoice_number}.pdf`, file, 'application/pdf', 'base64');
            this.isLoadingPdf = false;
        },
        async loadOrganisation() {
            if (this.invoice?.organisation_id)
                this.organisation = await this.$store.dispatch('organisation/getObject', this.invoice?.organisation_id);
        },
    },
    filters: {
        formatInvoiceStatus (status) {
            const mapping = {
                'ready': 'Klaar voor verwerken',
                'direct_debit_open': 'Incasso aangemeld',
                'direct_debit_canceled': 'Incasso geannuleerd door klant',
                'direct_debit_pending': 'Incasso in afwachting',
                'direct_debit_authorized': 'Incasso geautoriseerd',
                'direct_debit_expired': 'Incasso verlopen',
                'direct_debit_failed': 'Incasso mislukt',
                'direct_debit_chargeback': 'Incasso chargeback',
                'email_sent': 'Factuur gemaild',
                'reminder_sent': 'Herinnering gemaild',
                'paid': 'Betaald',
                'review': 'Review',
                'correction': 'Corrigeren',
                'manual': 'Handmatig',
                'ignored': 'Niet meer incasseren',
                'payment_agreement': 'Betalingsregeling getroffen',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
        formatLabelStatus (status) {
            const mapping = {
                'pending': 'Nog niet zichtbaar',
                'blocked': 'Land niet ondersteund',
                'incomplete': 'Niet volledig',
                'ready': 'Klaar voor verwerken',
                'label': 'Niet geprint',
                'printed': 'Geprint',
                'transit': 'Onderweg naar sorteercentrum',
                'depot': 'In sorteercentrum',
                'courier': 'Chauffeur is onderweg',
                'delivered': 'Bezorgd',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
    async created() {
        this.isLoading = true;
        // Make sure invoice is loaded
        await this.$store.dispatch('invoice/getObject', this.invoiceId);
        this.loadOrganisation();
        this.getLabels();
        this.isLoading = false;
    },
    beforeRouteUpdate (to, from, next) {
        this.invoiceId = to.params.invoiceId;
        next();
    },
};
</script>
