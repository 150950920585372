<template>
<form-page>
    <header class="container block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
            <b-button @click="$router.backOrDefault(defaultReturnRoute)" icon-left="arrow-left">
                Terug
            </b-button>
        </nav>
        <h1 class="title">Nieuwe factuur</h1>
    </header>


    <form id="invoice-form" @submit.prevent="submit">
        <section class="container block">
            <b-field label="Organisatie" v-if="organisation">
                <b-input :value="organisation.name" disabled></b-input>
            </b-field>
            <b-field
                    label="Factuurnummer"
                    :type="{'is-danger': errors.invoice_number.length > 0}"
                    :message="errors.invoice_number.join(', ')"
            >
                <b-radio-button v-model="fields.invoice_number" native-value="AUTO_INCREMENT">
                    Automatisch
                </b-radio-button>
                <!-- :value + @input hack to not un-highlight manual input when value is entered -->
                <b-radio-button
                    :value="fields.invoice_number === 'AUTO_INCREMENT' ? 'AUTO_INCREMENT' : ''"
                    @input="fields.invoice_number = ''"
                    native-value=""
                >
                    Handmatig
                </b-radio-button>
            </b-field>
            <b-field
                    v-show="fields.invoice_number !== 'AUTO_INCREMENT'"
                    :type="{'is-danger': errors.invoice_number.length > 0}"
                    :message="errors.invoice_number.join(', ')"
            >
                <b-input v-model="fields.invoice_number"></b-input>
            </b-field>
            <b-field
                label="Factuurdatum"
                :type="{'is-danger': errors.invoice_date.length > 0}"
                :message="errors.invoice_date.join(', ')"
            >
                <b-datepicker
                    v-model="fields.invoice_date"
                    icon="calendar-today"
                    locale="nl-NL"
                    editable
                    position="is-bottom-left"
                    ref="invoiceFormInvoiceDatepicker"
                >
                    <div class="buttons is-right">
                        <b-button type="is-primary" @click="toggleDatePicker('invoiceFormInvoiceDatepicker')">OK</b-button>
                    </div>
                </b-datepicker>
            </b-field>
            <b-field
                label="Verloopdatum"
                :type="{'is-danger': errors.due_date.length > 0}"
                :message="errors.due_date.join(', ')"
            >
                <b-datepicker
                    v-model="fields.due_date"
                    icon="calendar-today"
                    locale="nl-NL"
                    editable
                    position="is-bottom-left"
                    ref="invoiceFormDueDatepicker"
                >
                    <div class="buttons is-right">
                        <b-button type="is-primary" @click="toggleDatePicker('invoiceFormDueDatepicker')">OK</b-button>
                    </div>
                </b-datepicker>
            </b-field>
            <b-field
                label="Factuurperiode (optioneel)"
                grouped
                :type="{'is-danger': errors.invoice_date.length > 0}"
                :message="errors.invoice_date.join(', ')"
            >
                <b-field 
                    expanded
                    :type="{'is-danger': errors.invoice_period_start.length > 0}"
                    :message="errors.invoice_period_start.join(', ')"
                >
                    <b-datepicker
                        v-model="fields.invoice_period_start"
                        icon="calendar-today"
                        locale="nl-NL"
                        editable
                        position="is-bottom-left"
                        aria-label="Begindatum"
                        placeholder="Begindatum"
                        ref="invoiceFormInvoicePeriodStartDatepicker"
                    >
                        <div class="buttons is-right">
                            <b-button type="is-primary" @click="toggleDatePicker('invoiceFormInvoicePeriodStartDatepicker')">OK</b-button>
                        </div>
                    </b-datepicker>
                </b-field>
                <b-field 
                    expanded
                    :type="{'is-danger': errors.invoice_period_end.length > 0}"
                    :message="errors.invoice_period_end.join(', ')"
                >
                    <b-datepicker
                        v-model="fields.invoice_period_end"
                        icon="calendar-today"
                        locale="nl-NL"
                        editable
                        position="is-bottom-left"
                        aria-label="Einddatum"
                        placeholder="Einddatum"
                        ref="invoiceFormInvoicePeriodEndDatepicker"
                    >
                        <div class="buttons is-right">
                            <b-button type="is-primary" @click="toggleDatePicker('invoiceFormInvoicePeriodEndDatepicker')">OK</b-button>
                        </div>
                    </b-datepicker>
                </b-field>
            </b-field>
        </section>

        <section class="container block">
            <b-collapse 
                class="card"
                animation="slide"
                aria-id="labelSelectionCollapse"
                :open="false"
            >
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="labelSelectionCollapse"
                        :aria-expanded="props.open"
                    >
                        <div class="card-header-title">
                            <b-skeleton v-if="isLoading" width="10em" :animated="animated"></b-skeleton>
                            <h2 v-else class="has-text-weight-bold">
                                Labels
                                ({{ selectedLabels.length }}/{{ labels.length }})
                                <b-tag type="is-danger" v-if="errors.label_ids.length > 0">
                                    Fout
                                </b-tag>
                            </h2>
                        </div>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'chevron-up' : 'chevron-down'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <p v-if="errors.label_ids.length > 0" class="is-size-7 has-text-danger">
                        {{ errors.label_ids.join(', ') }}
                    </p>
                    <invoice-labels
                        :labels="labels"
                        :loading="isLoading"
                        :selection.sync="selectedLabels"
                        type="label_price"
                        @toggle-label="submitToggleLabel"
                    ></invoice-labels>
                </div>
            </b-collapse>
            <b-collapse 
                class="card"
                animation="slide"
                aria-id="surchargeSelectionCollapse"
                :open="false"
            >
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="surchargeSelectionCollapse"
                        :aria-expanded="props.open"
                    >
                        <div class="card-header-title">
                            <b-skeleton v-if="isLoading" width="10em" :animated="animated"></b-skeleton>
                            <h2 v-else class="has-text-weight-bold">
                                Naheffingen
                                ({{ selectedSurcharges.length }}/{{ surcharges.length }})
                                <b-tag type="is-danger" v-if="errors.surcharge_ids.length > 0">
                                    Fout
                                </b-tag>
                            </h2>
                        </div>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'chevron-up' : 'chevron-down'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <p v-if="errors.surcharge_ids.length > 0" class="is-size-7 has-text-danger">
                        {{ errors.surcharge_ids.join(', ') }}
                    </p>
                    <invoice-labels
                        :labels="surcharges"
                        :loading="isLoading"
                        :selection.sync="selectedSurcharges"
                        type="surcharge"
                        @toggle-label="submitToggleLabel"
                    ></invoice-labels>
                </div>
            </b-collapse>
            <b-collapse 
                class="card"
                animation="slide"
                aria-id="refundSelectionCollapse"
                :open="false"
            >
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="refundSelectionCollapse"
                        :aria-expanded="props.open"
                    >
                        <div class="card-header-title">
                            <b-skeleton v-if="isLoading" width="10em" :animated="animated"></b-skeleton>
                            <h2 v-else class="has-text-weight-bold">
                                Restituties
                                ({{ selectedRefunds.length }}/{{ refunds.length }})
                                <b-tag type="is-danger" v-if="errors.refund_ids.length > 0">
                                    Fout
                                </b-tag>
                            </h2>
                        </div>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'chevron-up' : 'chevron-down'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <p v-if="errors.refund_ids.length > 0" class="is-size-7 has-text-danger">
                        {{ errors.refund_ids.join(', ') }}
                    </p>
                    <invoice-labels
                        :labels="refunds"
                        :loading="isLoading"
                        :selection.sync="selectedRefunds"
                        type="refund"
                        @toggle-label="submitToggleLabel"
                    ></invoice-labels>
                </div>
            </b-collapse>
            <b-collapse 
                class="card"
                animation="slide"
                aria-id="planCollapse"
                :open="false"
            >
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="planCollapse"
                        :aria-expanded="props.open"
                    >
                        <div class="card-header-title">
                            <h2 class="has-text-weight-bold">
                                Abonnement
                                <b-tag type="is-danger" v-if="[...errors.include_plan, ...errors.plan_description].length > 0">
                                    Fout
                                </b-tag>
                            </h2>
                        </div>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'chevron-up' : 'chevron-down'">
                            </b-icon>
                        </a>
                        <b-loading :value="isLoading"></b-loading>
                    </div>
                </template>
                <div class="card-content">
                    <p v-if="[...errors.include_plan, ...errors.plan_description].length > 0" class="is-size-7 has-text-danger">
                        {{ [...errors.include_plan, ...errors.plan_description].join(', ') }}
                    </p>
                    <div class="b-table">
                        <div v-if="organisation && organisation.plan" class="table-wrapper">
                            <table class="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="checkbox-cell"></th>
                                        <th>Abonnement</th>
                                        <th>Omschrijving op factuur</th>
                                        <th class="has-text-right">Prijs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="checkbox-cell">
                                            <b-checkbox v-model="fields.include_plan">
                                                <span class="is-sr-only">Factureer abonnement</span>
                                            </b-checkbox>
                                        </td>
                                        <td>
                                            {{ organisation.plan.name }}
                                            <span class="has-text-grey">
                                                {{ organisation.plan.subscription.from|formatTimestamp('YYYY-MM-DD') }}
                                                <template v-if="organisation.plan.subscription.to">
                                                    tot {{ organisation.plan.subscription.to|formatTimestamp('YYYY-MM-DD') }}
                                                </template>
                                            </span>
                                            <span v-if="organisation.plan.subscription.subscriptionperiod" class="tag">
                                                <template v-if="organisation.plan.subscription.subscriptionperiod == 'monthly'">
                                                    maandelijks
                                                </template>
                                                <template v-else-if="organisation.plan.subscription.subscriptionperiod == 'yearly'">
                                                    jaarlijks
                                                </template>
                                                <template v-else>
                                                    onbekende periode
                                                </template>
                                            </span>
                                        </td>
                                        <td>
                                            <label for="plan-description-field" class="is-sr-only">Abonnement omschrijving</label>
                                            <b-input v-model="fields.plan_description" size="is-small" id="plan-description-field" autocomplete="off"></b-input>
                                        </td>
                                        <td class="has-text-right">
                                            {{ planPrice|formatMoney }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p v-else>Deze organisatie heeft geen abonnement</p>
                    </div>
                </div>
            </b-collapse>
        </section>

        <section class="container block">
            <h2 class="title is-4">Handmatige factuurregels</h2>
            <form @submit.prevent="createInvoiceItem">
                <b-table
                    :data="fields.invoice_items"
                    :loading="isLoadingInvoiceItems"
                    :striped="true"
                    :hoverable="true"
                    :mobile-cards="false"
                >
                    <b-table-column field="description" label="Omschrijving" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.description }}
                        <error-indicator 
                            v-if="errors.invoice_items[props.index] && errors.invoice_items[props.index].description"
                            :errors="errors.invoice_items[props.index].description"
                        ></error-indicator>
                    </b-table-column>
                    <b-table-column field="quantity" label="Aantal" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.quantity }}
                        <error-indicator 
                            v-if="errors.invoice_items[props.index] && errors.invoice_items[props.index].quantity"
                            :errors="errors.invoice_items[props.index].quantity"
                        ></error-indicator>
                    </b-table-column>
                    <b-table-column field="unit_price" label="Prijs per stuk (excl. BTW)" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.unit_price|formatMoney }}
                        <error-indicator 
                            v-if="errors.invoice_items[props.index] && errors.invoice_items[props.index].unit_price"
                            :errors="errors.invoice_items[props.index].unit_price"
                        ></error-indicator>
                    </b-table-column>
                    <b-table-column field="vat" label="BTW %" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.vat }}%
                        <error-indicator 
                            v-if="errors.invoice_items[props.index] && errors.invoice_items[props.index].vat"
                            :errors="errors.invoice_items[props.index].vat"
                        ></error-indicator>
                    </b-table-column>
                    <b-table-column field="total" label="Totaalprijs (excl. BTW)" cell-class="is-vcentered" v-slot="props">
                        {{ (parseFloat(props.row.unit_price) * parseFloat(props.row.quantity))|formatMoney }}
                    </b-table-column>
                    <b-table-column field="bookkeeping_account" label="Grootboekrekening" cell-class="is-vcentered" v-slot="props">
                        {{ props.row.bookkeeping_account }}
                        <error-indicator 
                            v-if="errors.invoice_items[props.index] && errors.invoice_items[props.index].bookkeeping_account"
                            :errors="errors.invoice_items[props.index].bookkeeping_account"
                        ></error-indicator>
                    </b-table-column>
                    <b-table-column numeric cell-class="is-vcentered" v-slot="props">
                        <b-button size="is-small" native-type="button" @click="deleteInvoiceItem(props.index)">
                            <span class="icon is-small">
                                <i class="gg-trash" aria-hidden="true"></i>
                                <span class="is-sr-only">Verwijderen</span>
                            </span>
                        </b-button>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">Geen handmatige factuurregels</div>
                    </template>
                    
                    <template #footer>
                        <td>
                            <label for="invoice-item-description-field" class="is-sr-only">Omschrijving</label>
                            <b-input v-model="newInvoiceItem.description" size="is-small" id="invoice-item-description-field" required ref="invoiceItemDescriptionField"></b-input>
                        </td>
                        <td>
                            <label for="invoice-item-quantity-field" class="is-sr-only">Aantal</label>
                            <b-input v-model="newInvoiceItem.quantity" type="number" step="1" min="0" size="is-small" id="invoice-item-quantity-field" required></b-input>
                        </td>
                        <td>
                            <label for="invoice-item-unit-price-field" class="is-sr-only">Prijs (exclusief BTW)</label>
                            <b-input v-model="newInvoiceItem.unit_price" type="number" step="0.01" size="is-small" id="invoice-item-unit-price-field" required></b-input>
                        </td>
                        <td>
                            <label for="invoice-item-vat-field" class="is-sr-only">BTW Percentage</label>
                            <b-select v-model="newInvoiceItem.vat" id="invoice-item-vat-field" size="is-small" expanded>
                                <option :value="0">0%</option>
                                <option :value="9">9%</option>
                                <option :value="21">21%</option>
                            </b-select>
                        </td>
                        <td>
                            <input
                                class="input is-small"
                                type="text"
                                readonly
                                disabled
                                :value="((parseFloat(newInvoiceItem.unit_price) || 0) * (parseFloat(newInvoiceItem.quantity) || 0))|formatMoney"
                            >
                        </td>
                        <td>
                            <label for="invoice-item-bookkeeping_account-field" class="is-sr-only">Grootboekrekening</label>
                            <!-- <b-input v-model="newInvoiceItem.bookkeeping_account" size="is-small" id="invoice-item-bookkeeping_account-field"></b-input> -->
                            <b-autocomplete
                                v-model="newInvoiceItem.bookkeeping_account"
                                :data="bookkeepingAccountAutocomplete"
                                :loading="isLoadingBookkeepingAccountAutocomplete"
                                clearable
                                @typing="handleBookkeepingAccountAutocomplete"
                                size="is-small"
                                id="invoice-item-bookkeeping_account-field"
                            ></b-autocomplete>
                        </td>
                        <td class="has-text-right">
                            <b-button size="is-small" native-type="submit">Toevoegen</b-button>
                        </td>
                    </template>
                </b-table>
            </form>
        </section>
    </form>

    <template #footer>
        <div class="container">
            <div class="level is-mobile">
                <div class="level-left">
                    <p>
                        <strong>Totaal: {{ total.excl_vat|formatMoney }}</strong>
                        ({{ total.incl_vat|formatMoney }} incl. BTW)
                    </p>
                </div>
                <div class="level-right buttons">
                    <b-button type="is-primary" native-type="submit" form="invoice-form" :loading="isSubmitting">
                        Factuur aanmaken
                    </b-button>
                </div>
            </div>
        </div>
    </template>
</form-page>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { api } from '@/utils';
import { debounce, getMoment } from '@/utils/functions';
import ErrorIndicator from '@/components/ErrorIndicator';
import InvoiceLabels from '@/components/InvoiceLabels';
import FormPage from '@/components/FormPage';
import {INVOICE_DUE_PERIOD} from '@/constants.js';

export default {
    components: {ErrorIndicator, FormPage, InvoiceLabels},
    data() {
        const invoiceDate = new Date();
        const dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + INVOICE_DUE_PERIOD)
        return {
            isSubmitting: false,
            isLoadingInvoiceItems: true, // we're loading untill organisation is loaded
            isLoadingLabels: false,
            bookkeepingAccountAutocomplete: [],
            isLoadingBookkeepingAccountAutocomplete: false,
            defaultReturnRoute: { name: 'invoice-create-list' },
            organisation: {},
            allLabels: [],
            selectedLabels: [],
            selectedSurcharges: [],
            selectedRefunds: [],
            newInvoiceItem: {
                description: null,
                quantity: null,
                unit_price: null,
                vat: 21,
                bookkeeping_account: null,
            },
            fields: {
                invoice_date: invoiceDate,
                invoice_number: 'AUTO_INCREMENT',
                invoice_period_start: this.$store.state.invoice.invoicePeriod.start,
                invoice_period_end: this.$store.state.invoice.invoicePeriod.end,
                due_date: dueDate,
                include_plan: false,
                invoice_items: [],
                plan_description: null,
            },
            errors: {
                invoice_date: [],
                invoice_number: [],
                invoice_period_start: [],
                invoice_period_end: [],
                due_date: [],
                include_plan: [],
                invoice_items: [],
                plan_description: [],
                label_ids: [],
                surcharge_ids: [],
                refund_ids: [],
            },
        };
    },
    computed: {
        ...mapState({
            couriers: state => state.courier.all,
        }),
        labels() {
            return this.allLabels?.filter(
                l => l.invoice_items?.some(i => i.type === 'label_price' && !i.invoice_id)
            ) || [];
        },
        surcharges() {
            return this.allLabels?.filter(
                l => l.invoice_items?.some(i => i.type === 'surcharge' && !i.invoice_id)
            ) || [];
        },
        refunds() {
            return this.allLabels?.filter(
                l => l.invoice_items?.some(i => i.type === 'refund' && !i.invoice_id)
            ) || [];
        },
        isLoading() {
            return this.$store.state.courier.isLoading || this.isLoadingLabels;
        },
        planPrice() {
            if (!this.organisation)
                return null;

            const plan = this.organisation.plan;
            if (plan && plan.subscription?.subscriptionperiod === 'monthly')
                return plan.payment.pricepermonth_monthly;
            else if (plan && plan.subscription?.subscriptionperiod === 'yearly' && plan.payment.pricepermonth_yearly)
                return 12 * plan.payment.pricepermonth_yearly;
            return null;
        },
        total() {
            let total = {
                excl_vat: 0,
                incl_vat: 0,
            };
            for (const label of this.selectedLabels) {
                const t = this.getLabelInvoiceItemsTotal(label, 'label_price');
                total.excl_vat += t.excl_vat;
                total.incl_vat += t.incl_vat;
            }
            for (const label of this.selectedSurcharges) {
                const t = this.getLabelInvoiceItemsTotal(label, 'surcharge');
                total.excl_vat += t.excl_vat;
                total.incl_vat += t.incl_vat;
            }
            for (const label of this.selectedRefunds) {
                const t = this.getLabelInvoiceItemsTotal(label, 'refund');
                total.excl_vat += t.excl_vat;
                total.incl_vat += t.incl_vat;
            }
            if (this.fields.include_plan) {
                total.excl_vat += this.planPrice ?? 0;
                total.incl_vat += (this.planPrice ?? 0) * 1.21;
            }
            for (const item of this.fields.invoice_items) {
                total.excl_vat += parseFloat(item.quantity) * parseFloat(item.unit_price);
                total.incl_vat += parseFloat(item.quantity) * parseFloat(item.unit_price) * (1 + (parseFloat(item.vat) / 100));
            }
            return total;
        },
    },
    methods: {
        async createInvoiceItem() {
            // Hope for native form validation
            this.fields.invoice_items.push(this.newInvoiceItem);
            this.newInvoiceItem = {
                description: null,
                quantity: null,
                unit_price: null,
                vat: 21,
            };
            await this.submitInvoiceItems(this.fields.invoice_items);
            this.$refs.invoiceItemDescriptionField.focus();
        },
        async deleteInvoiceItem(idx) {
            this.fields.invoice_items.splice(idx, 1);
            await this.submitInvoiceItems(this.fields.invoice_items);
        },
        getCourier(courierId) {
            return this.couriers.find(c => c.id == courierId);
        },
        getLabelInvoiceItems(label, type) {
            return label.invoice_items?.filter(i => i.type === type).map(i => i.parts).flat();
        },
        getLabelInvoiceItemsTotal(label, type) {
            return this.getLabelInvoiceItems(label, type)?.reduce((acc, value) => ({
                excl_vat: acc.excl_vat + parseFloat(value.unit_price),
                incl_vat: acc.incl_vat + parseFloat(value.unit_price) * (1 + (parseFloat(value.vat) / 100)),
            }),
            {
                excl_vat: 0,
                incl_vat: 0,
            });
        },
        getPlanDescription(organisation) {
            if (!organisation)
                organisation = this.organisation;
            if (organisation && organisation.plan && organisation.plan.subscription?.subscriptionperiod === 'monthly') {
                const month = Vue.filter('titleCase')(getMoment().format('MMMM YYYY'));
                return `Abonnement: ${organisation.plan.name} – ${month}`;
            } else if (organisation && organisation.plan) {
                return `Abonnement: ${organisation.plan.name}`;
            }
        },
        async loadCouriers() {
            // async so this call can run in the background
            if (this.allLabels) {
                const ids = [...new Set(this.allLabels
                    .filter(o => o.service_id && !this.getCourier(o.service_id))
                    .map(o => o.service_id)
                )];
                if (ids.length > 0)
                    await this.$store.dispatch('courier/getCouriers', {ids});
            }
        },
        async loadLabels() {
            this.isLoadingLabels = true;
            this.allLabels = await api.getInvoiceLabels(undefined, {
                organisation_id: this.$route.params.organisationId,
            });
            this.isLoadingLabels = false;
        },
        async loadOrganisation() {
            this.organisation = await this.$store.dispatch('organisation/getObject', this.$route.params.organisationId);
        },
        hasLabelNoValue(){
            for (const label of this.labels) {
                const invoice_item = label.invoice_items.filter(o => o.type==='label_price')[0]
                const zeroValues = invoice_item.parts.filter(o => o.unit_price === 0)
                if (zeroValues?.length > 0)
                    return true
            }
            return false
        },
        submit() {
            if (this.hasLabelNoValue() === true) {
                this.$buefy.dialog.confirm({
                title: 'Label zonder prijs/waarde gedetecteerd!',
                message: `Let op! Er is een label met een 0 prijs. Weet je zeker dat je een factuur wil aanmaken?`,
                cancelText: 'Annuleren',
                confirmText: 'Factuur aanmaken',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    this._submit()
                }
                });
            } else {
                this._submit()
            }
        },
        async _submit() {
            this.isSubmitting = true;
            const invoice = {
                invoice_number: this.fields.invoice_number,
                invoice_date: getMoment(this.fields.invoice_date).format('YYYY-MM-DD'),
                due_date: getMoment(this.fields.due_date).format('YYYY-MM-DD'),
                invoice_period_start: (this.fields.invoice_period_start 
                                        ? getMoment(this.fields.invoice_period_start).format('YYYY-MM-DD')
                                        : null),
                invoice_period_end: (this.fields.invoice_period_end 
                                        ? getMoment(this.fields.invoice_period_end).format('YYYY-MM-DD')
                                        : null),
                invoice_items: this.fields.invoice_items,
                include_plan: this.fields.include_plan,
                plan_description: this.fields.plan_description,
                organisation_id: this.organisation.id,
                label_ids: this.selectedLabels.map(l => l.id),
                surcharge_ids: this.selectedSurcharges.map(l => l.id),
                refund_ids: this.selectedRefunds.map(l => l.id),
            };
            try {
                const invoiceId = await this.$store.dispatch('invoice/createInvoice', invoice);
                if (invoice.invoice_period_start)
                    this.$store.commit('invoice/setInvoicePeriod', {
                        start: this.fields.invoice_period_start,
                        end: this.fields.invoice_period_end,
                    });
                // Use push for navigation, as action is not semantically equivalent to going back in history
                this.$router.push({ name: 'invoice-single', params: {invoiceId: invoiceId, returnRoute: { name: 'invoice-create-list'}} });
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;

                    if (errors.invoice)
                        this.$buefy.dialog.alert({
                            title: 'Fout in factuur!',
                            message: errors.invoice,
                            type: 'is-danger',
                            hasIcon: true,
                        });

                    for (const field in errors) {
                        if (field === 'invoice_items')
                            this.errors[field] = errors[field];
                        else if (field !== 'invoice')
                            this.errors[field] = this.errors[field].concat(errors[field]);
                    }
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        async submitInvoiceItems(items) {
            this.isLoadingInvoiceItems = true;
            try {
                await this.$store.dispatch('organisation/updateOrganisation', {
                    id: this.organisation.id,
                    updates: {invoice_items: items}
                });
            } catch (e) {
                if (e.response && e.response.type === 'validation_error') {
                    let errors = e.response.data;
                    if (errors.invoice_items)
                        this.errors.invoice_items = errors.invoice_items;
                }
            } finally {
                this.isLoadingInvoiceItems = false;
            }
        },
        async submitToggleLabel({label, type, action}) {
            let updates = {};
            updates[`${action}_invoice_items`] = type;
            const result = await api.updateLabel(label.id, updates);
            const idx = this.allLabels.indexOf(label);
            if (idx >= 0)
                this.allLabels.splice(idx, 1, result.data);
            else
                this.allLabels.push(result.data);
        },
        initSelection(labels) {
            // Default is all labels selected
            let selection = [...labels];
            if (this.fields.invoice_period_start)
                selection = selection.filter(l => getMoment(l.first_tracked_at).isAfter(this.fields.invoice_period_start));
            if (this.fields.invoice_period_end)
                selection = selection.filter(
                    // add 1 day, as date comparison should be start <= date <= end
                    l => getMoment(l.first_tracked_at).isBefore(getMoment(this.fields.invoice_period_end).add(1, 'day'))
                );
            return selection;
        },
        selectLabels() {
            this.selectedLabels = this.initSelection(this.labels || []);
            this.selectedSurcharges = this.initSelection(this.surcharges || []);
            this.selectedRefunds = this.initSelection(this.refunds || []);
        },
        toggleDatePicker(ref) {
            this.$refs[ref].toggle();
        },
        handleBookkeepingAccountAutocomplete: debounce(async function (name) {
            if (!name.length) {
                this.bookkeepingAccountAutocomplete = [];
            } else {
                this.isLoadingBookkeepingAccountAutocomplete = true;
                const result = await api.getAccounts({autocomplete: name});
                this.bookkeepingAccountAutocomplete = result?.data || [];
                this.isLoadingBookkeepingAccountAutocomplete = false;
            }
        }, 300),
    },
    created() {
        this.loadOrganisation();
        this.loadLabels();
        this.selectLabels();
    },
    watch: {
        allLabels: {
            handler(value) {
                if (value) {
                    this.selectLabels();
                    this.loadCouriers();
                }
            },
            immediate: true,
        },
        organisation: {
            handler(organisation) {
                this.fields.plan_description = this.getPlanDescription(organisation);
                if (organisation && Object.keys(organisation).length !== 0) {
                    this.isLoadingInvoiceItems = false;
                    this.fields.invoice_items = organisation?.invoice_items || [];
                }
            },
            immediate: true,
        },
        'fields.invoice_date'(date) {
            const dueDate = new Date(date.getTime());
            dueDate.setDate(dueDate.getDate() + INVOICE_DUE_PERIOD);
            this.fields.due_date = dueDate;
        },
        'fields.invoice_period_start': {
            handler() {
                this.selectLabels();
            },
            immediate: true,
        },
        'fields.invoice_period_end': {
            handler() {
                this.selectLabels();
            },
            immediate: true,
        },
    },
};
</script>
